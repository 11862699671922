import Footer from '../components/footer';
import Press from '../components/OneSheet/press';
import SocialStatsSection from '../components/OneSheet/social-stats';
import PastGigsSection from '../components/OneSheet/pastGigs-section';
import { ParallaxBanner } from 'react-scroll-parallax';
import LiveVideo from '../components/OneSheet/live-video';
import SwimPrIntro from '../components/swimPr/swimPr-Intro';
import SwimSection from '../components/swimPr/swimSection';
import Bio from '../components/OneSheet/bio';
import Live, { tempGigs } from '../components/home/live-section';
import PrSummaryBox from '../components/OneSheet/pr-summary-box';
import SupportedBy from '../components/OneSheet/supported-by';

function SwimPr() {

    const displayGigs = tempGigs.filter(gig =>
        gig.showInOneSheet && new Date() > gig.visibleDate && new Date() < gig.expireDate
    );

    return (
        <div className="home">
            <SwimPrIntro />

            <SwimSection />

            <ParallaxBanner
                layers={[
                    { image: './img/bandshot-bluelight.JPG', speed: -20 }
                ]}
                className="aspect-[2/1]"
                style={{ height: '500px' }}
            />

            <Bio />

            <ParallaxBanner
                layers={[
                    { image: './img/onesheet/swimvidphoto2.JPG', speed: -20 }
                ]}
                className="aspect-[2/1]"
                style={{ height: '500px' }}
            />

            <PrSummaryBox />

            <LiveVideo />

            <ParallaxBanner
                layers={[
                    { image: './img/onesheet/swimvidphoto1.JPG', speed: -20 }
                ]}
                className="aspect-[2/1]"
                style={{ height: '500px' }}
            />

            <Press />

            <ParallaxBanner
                layers={[
                    { image: './img/IMG_5901.JPG', speed: -20 }
                ]}
                className="aspect-[2/1]"
                style={{ height: '500px' }}
            />

            {displayGigs.length > 0 && <Live />}

            <PastGigsSection />
            <ParallaxBanner
                layers={[
                    { image: './img/onesheet/amazons-crowdshot2.JPG', speed: -30, }
                ]}
                className="aspect-[2/1]"
                style={{ height: '900px' }}
            />

            <SocialStatsSection />

            <ParallaxBanner
                layers={[
                    { image: './img/onesheet/marshall-bandpic.JPG', speed: -20 }
                ]}
                className="aspect-[2/1]"
                style={{ height: '500px' }}
            />

            <SupportedBy />

            <Footer />
        </div>
    );
}

export default SwimPr;