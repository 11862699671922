import React from "react";

import { Col, Row } from "react-bootstrap";

function MailingList() {
    return (
        <section className="mailinglist" id="mailinglist">
            <Row className="section-intro" style={{ display: "flex", justifyContent: "center", paddingLeft: "30px", paddingRight: "30px"}}>
                <Col className="col-twelve highlight-box">
                    <Row className="section-intro" >
                        <Col className="col-twelve">
                            <h5>MAILING LIST</h5>
                            <p className="lead">
                                Sign up to hear about latest gigs, releases + more!
                            </p>
                        </Col>
                    </Row>
                    <Row className="contact-form">
                        <Col className="col-twelve">
                            <div id="mc_embed_shell">
                                <div id="mc_embed_signup">
                                    <form
                                        action={"https://avainthedark.us20.list-manage.com/subscribe/post?u=af7d4a515cdf01eb1124e8e43&amp;id=57bc0a69bc&amp;f_id=00a978eaf0"}
                                        method="post"
                                        id="mc-embedded-subscribe-form"
                                        name="mc-embedded-subscribe-form"
                                        className="validate"
                                        target="_blank"
                                        noValidate
                                    >
                                        <div id="mc_embed_signup_scroll">
                                            <div className="mc-field-group">
                                                <input
                                                    type="text"
                                                    name="FNAME"
                                                    placeholder="Name"
                                                    className="text"
                                                    id="mce-FNAME"
                                                    defaultValue=""
                                                />
                                            </div>
                                            <div className="mc-field-group">
                                                <input
                                                    type="email"
                                                    name="EMAIL"
                                                    className="required email"
                                                    placeholder="Email"
                                                    id="mce-EMAIL"
                                                    required
                                                    defaultValue=""
                                                    aria-required="true"
                                                />
                                            </div>

                                            <div hidden>
                                                <input type="hidden" name="tags" defaultValue="527154" />
                                            </div>
                                            <div id="mce-responses" className="clear foot">
                                                <div
                                                    className="response"
                                                    id="mce-error-response"
                                                    style={{ display: "none" }}
                                                ></div>
                                                <div
                                                    className="response"
                                                    id="mce-success-response"
                                                    style={{ display: "none" }}
                                                ></div>
                                            </div>
                                            <div
                                                aria-hidden="true"
                                                style={{ position: 'absolute', left: '-5000px' }}>
                                                <input
                                                    type="text"
                                                    name="b_af7d4a515cdf01eb1124e8e43_57bc0a69bc"
                                                    tabIndex={-1}
                                                    defaultValue=""
                                                />
                                            </div>

                                            <button
                                                type="submit"
                                                name="subscribe"
                                                id="mc-embedded-subscribe"
                                                className="button submitform"
                                                value="Subscribe"
                                            >
                                                Subscribe
                                            </button>
                                            <p style={{ margin: '0px auto' }}>
                                                <a
                                                    href="http://eepurl.com/hRF2dn"
                                                    title="Mailchimp - email marketing made easy and fun">
                                                    <span
                                                        style={{
                                                            display: "inline-block",
                                                            background: "black",
                                                            borderRadius: "4px",
                                                        }}>
                                                        {/* <img
                                                    className="refferal_badge"
                                                    src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-light.svg"
                                                    alt="Intuit Mailchimp"
                                                    style={{
                                                        padding: "2px 0px",
                                                        width: "220px",
                                                        height: "40px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }} /> */}
                                                    </span>
                                                </a>
                                            </p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <script
                                type="text/javascript"
                                src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
                            ></script>

                        </Col>
                    </Row >
                </Col>
            </Row >
        </section >
    );
}

export default MailingList;
