import Intro from "../components/home/intro-section";
import MailingList
    from "../components/home/mailinglist-section";
import Footer from "../components/footer";
import Live from "../components/home/live-section";
import { tempGigs } from "../components/home/live-section";

function Home() {

    const displayGigs = tempGigs.filter(gig =>
        gig.showInLiveSection && new Date() > gig.visibleDate && new Date() < gig.expireDate
    );

    return (
        <div className="home">
            <Intro />
            {displayGigs.length > 0 && <Live />}
            <MailingList />
            <Footer />
        </div>
    );
}

export default Home;
