import { Col, Row } from "react-bootstrap";
import { ILinks } from "../../services/interfaces";

interface LinkListProps {
    links: ILinks[];
}

function LinkList({ links }: LinkListProps) {
    const displayLinks = links.filter(link =>
        link.visible &&
        new Date() > link.visibleDate &&
        (link.expireDate === null || new Date() < link.expireDate)
    );

    return (
        <div className="links">
            <Row className="links-list">
                {displayLinks.map(link => (
                    <div key={link.id} className="link-item">
                        <a href={link.url} target="_blank" rel="noreferrer" className="link" style={{ width: "100%" }}>
                            <Row style={{ alignItems: "center", width: "100%", margin: "0" }}>
                                <Col className="col-two" style={{ flex: "none", padding: "0", textAlign: "left" }}>
                                    <img
                                        src={link.icon}
                                        className="link-img"
                                        alt={`Icon for ${link.title}`}
                                    />
                                </Col>
                                <Col className="col-ten">
                                    <h5>{link.title}</h5>
                                </Col>
                            </Row>
                        </a>
                    </div>
                ))}
            </Row>
        </div>
    );
}

export default LinkList;