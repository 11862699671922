import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { IGig } from '../../services/interfaces';

type EditGigModalProps = {
  show: boolean;
  handleClose: () => void;
  gig: IGig;
  // handleSave: (gig: IGig) => void;
};

const EditGigModal: React.FC<EditGigModalProps> = ({ show, handleClose, gig }) => {
  const [editGig, setEditGig] = useState<IGig>(gig);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setEditGig({ ...editGig, [name]: value });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setEditGig({ ...editGig, [name]: checked });
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditGig({ ...editGig, [name]: new Date(value) });
  };

  const saveChanges = () => {
    // handleSave(editGig);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className='description-box'>
        <Modal.Title>Edit Gig</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='media mb-3'>
          <img src={editGig.imageUrl} alt={editGig.altTxt} className='img-fluid' />
        </div>
        <Form>
          <Form.Group controlId="formLocation">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              name="location"
              value={editGig.location}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formVenue">
            <Form.Label>Venue</Form.Label>
            <Form.Control
              type="text"
              name="venue"
              value={editGig.venue}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formDate">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              name="date"
              value={editGig.date.toISOString().substr(0, 10)}
              onChange={handleDateChange}
            />
          </Form.Group>
          <Form.Group controlId="formImageUrl">
            <Form.Label>Image URL</Form.Label>
            <Form.Control
              type="text"
              name="imageUrl"
              value={editGig.imageUrl}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formAltTxt">
            <Form.Label>Alt Text</Form.Label>
            <Form.Control
              type="text"
              name="altTxt"
              value={editGig.altTxt}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formLink">
            <Form.Label>Link</Form.Label>
            <Form.Control
              type="text"
              name="link"
              value={editGig.link}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formVisible">
            <Form.Check
              type="checkbox"
              label="Show in gigs section"
              name="showInLiveSection"
              checked={editGig.showInLiveSection}
              onChange={handleCheckboxChange}
            />
             <Form.Check
              type="checkbox"
              label="Show in links page"
              name="showInLinksPage"
              checked={editGig.showInLinksPage}
              onChange={handleCheckboxChange}
            />
             <Form.Check
              type="checkbox"
              label="Show in one sheet"
              name="showInOneSheet"
              checked={editGig.showInOneSheet}
              onChange={handleCheckboxChange}
            />
          </Form.Group>
          <Form.Group controlId="formVisibleDate">
            <Form.Label>Visible Date</Form.Label>
            <Form.Control
              type="date"
              name="visibleDate"
              value={editGig.visibleDate.toISOString().substr(0, 10)}
              onChange={handleDateChange}
            />
          </Form.Group>
          <Form.Group controlId="formExpireDate">
            <Form.Label>Expire Date</Form.Label>
            <Form.Control
              type="date"
              name="expireDate"
              value={editGig.expireDate.toISOString().substr(0, 10)}
              onChange={handleDateChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className='link-box'>
        <Button variant="secondary" onClick={handleClose} className='button'>
          Close
        </Button>
        <Button variant="primary" onClick={saveChanges} className='button submitform'>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditGigModal;
