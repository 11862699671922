import { Col, Row } from "react-bootstrap";
import FolioItem from "../live/folio-item";
import { IGig } from "../../services/interfaces";

function PastGigsSection() {

    return (
        <section className="live" id="live">
            {/* <video playsInline autoPlay muted loop poster="../images/live-bg.JPG" src="./img/AmazonsClips.mp4" id="bgvid" typeof="video/mp4" /> */}

            <Row className="section-intro">
                <Col className="col-twelve" >
                    <h5>PAST GIGS</h5>
                    <h3>
                        <a href="https://www.setlist.fm/setlists/ava-in-the-dark-53fb8369.html" target="_blank" rel="noopener noreferrer">
                            {"VIEW ALL PAST GIGS ->"}
                        </a>
                    </h3>
                </Col>
            </Row>
            <Row className="portfolio-content" >
                <Col className="col-twelve" >
                    <div className="block-1-3 block-mob-full stack" style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                        {pastGigs.map((gig, index) => (
                            gig.showInPastGigsSection && new Date() > gig.visibleDate && new Date() < gig.expireDate &&
                            <FolioItem key={index} gig={gig} />
                        ))
                        }
                    </div>
                </Col>
            </Row>
        </section>
    );
}

export default PastGigsSection;

export const pastGigs: IGig[] = [
    {
        id: 1,
        location: "Leeds",
        venue: "Brudenell Social Club",
        //months are from 0!
        date: new Date(2024, 7, 11),
        imageUrl: "./img/pastGigPosters/Silver_Moth_Poster.jpg",
        videoPosterUrl: null,
        altTxt: "11thAug_SilverMoth_Poster",
        link: "https://mailchi.mp/brudenellsocialclub/silver-moth-live-in-leeds",
        showInLiveSection: false,
        showInLinksPage: false,
        showInOneSheet: false,
        showInPastGigsSection: true,
        //months are from 0!
        visibleDate: new Date(2024, 1, 14),
        //months are from 0!
        expireDate: new Date(2100, 9, 4)
    },
    {
        id: 2,
        location: "Leeds Beckett SU",
        venue: "Main Support for The Amazons UK Tour",
        date: new Date(2022, 9, 13),
        imageUrl: "./img/pastGigPosters/amazonsposter2.JPG",
        // videoPosterUrl: "./img/AmazonsClips.mp4",
        videoPosterUrl: null,
        altTxt: "Amazons poster",
        link: "https://www.songkick.com/concerts/40314101-amazons-at-leeds-beckett-university-students-union",
        showInLiveSection: false,
        showInLinksPage: false,
        showInOneSheet: false,
        showInPastGigsSection: true,
        visibleDate: new Date(2024, 1, 14),
        expireDate: new Date(2124, 2, 14)
    },
    {
        id: 3,
        location: "Leeds",
        venue: "Hyde Park Book Club",
        date: new Date(2024, 6, 28),
        imageUrl: "./img/pastGigPosters/ohphantomposter.jpeg",
        videoPosterUrl: null,
        altTxt: "ohPhantomHydeParkPoster",
        link: "https://www.seetickets.com/event/o-phantom-ava-in-the-dark-hyde-park-book-club/hyde-park-book-club/3055805?aff=id1songkick",
        showInLiveSection: false,
        showInLinksPage: false,
        showInOneSheet: false,
        showInPastGigsSection: true,
        visibleDate: new Date(2024, 1, 14),
        expireDate: new Date(2126, 2, 14)
    },
    {
        id: 4,
        location: "Leeds Central Library",
        venue: "SOFAR SOUNDS",
        date: new Date(2022, 10, 25),
        imageUrl: "./img/pastGigPosters/sofar.JPG",
        videoPosterUrl: null,
        // videoPosterUrl: "./img/onesheet/SofarClips.mp4",
        altTxt: "Sofar Sounds Poster",
        link: "",
        showInLiveSection: false,
        showInLinksPage: false,
        showInOneSheet: false,
        showInPastGigsSection: true,
        visibleDate: new Date(2024, 1, 14),
        expireDate: new Date(2126, 2, 14)
    },
    {
        id: 5,
        location: "Leeds",
        venue: "Oporto",
        date: new Date(2024, 6, 28),
        imageUrl: "./img/pastGigPosters/cielposter4.JPG",
        // videoPosterUrl: "https://www.youtube.com/watch?v=uzFADpJe0R4",
        videoPosterUrl: null,
        altTxt: "Oporto Ciel Poster",
        link: "",
        showInLiveSection: false,
        showInLinksPage: false,
        showInOneSheet: false,
        showInPastGigsSection: true,
        visibleDate: new Date(2024, 1, 14),
        expireDate: new Date(2126, 2, 14)
    },
    {
        id: 6,
        location: "",
        venue: "SPYRES UK TOUR",
        date: new Date(2023, 9, 3),
        imageUrl: "./img/pastGigPosters/spyrestour.jpg",
        videoPosterUrl: null,
        altTxt: "Spyres UK Tour Poster",
        link: "https://www.seetickets.com/tour/spyres",
        showInLiveSection: false,
        showInLinksPage: false,
        showInOneSheet: false,
        showInPastGigsSection: true,
        visibleDate: new Date(2024, 1, 14),
        expireDate: new Date(2124, 2, 14)
    },
    {
        id: 7,
        location: "Leeds",
        venue: "LIVE AT LEEDS",
        date: new Date(2021, 9, 16),
        imageUrl: "./img/pastGigPosters/liveatleeds.jpg",
        videoPosterUrl: null,
        altTxt: "Live At Leeds Poster",
        link: "https://www.launchpad-music.com/past-events-blog/liveatleeds2021",
        showInLiveSection: false,
        showInLinksPage: false,
        showInOneSheet: false,
        showInPastGigsSection: true,
        visibleDate: new Date(2024, 1, 14),
        expireDate: new Date(2124, 2, 14)
    },
    {
        id: 8,
        location: "Wrexham",
        venue: "FOCUS WALES",
        date: new Date(2024, 2, 14),
        imageUrl: "./img/pastGigPosters/focuswalesposter.jpg",
        videoPosterUrl: null,
        altTxt: "FOCUS WALES Poster",
        link: "https://www.godisinthetvzine.co.uk/2022/03/10/news-75-new-acts-including-benji-wild-ani-glass-ailsa-tully-deep-tan-kiddus-szwe-and-many-more-added-to-huge-focus-wales-2022-line-up/",
        showInLiveSection: false,
        showInLinksPage: false,
        showInOneSheet: false,
        showInPastGigsSection: true,
        visibleDate: new Date(2024, 1, 14),
        expireDate: new Date(2124, 2, 14)
    },
    {
        id: 9,
        location: "London",
        venue: "The Hanwell Hootie",
        date: new Date(2022, 4, 7),
        imageUrl: "./img/pastGigPosters/hanwellhootie.jpg",
        videoPosterUrl: null,
        altTxt: "Hanwell Hootie Poster",
        link: "",
        showInLiveSection: false,
        showInLinksPage: false,
        showInOneSheet: false,
        showInPastGigsSection: true,
        visibleDate: new Date(2024, 1, 14),
        expireDate: new Date(2124, 2, 14)
    },
    {
        id: 10,
        location: "Wakefield",
        venue: "Long Division",
        date: new Date(2022, 5, 11),
        imageUrl: "./img/pastGigPosters/longdivision poster.jpg",
        videoPosterUrl: null,
        altTxt: "Long Division Poster",
        link: "",
        showInLiveSection: false,
        showInLinksPage: false,
        showInOneSheet: false,
        showInPastGigsSection: true,
        visibleDate: new Date(2024, 1, 14),
        expireDate: new Date(2124, 2, 14)
    },
    {
        id: 11,
        location: "Leeds",
        venue: "Hyde Park Book Club",
        date: new Date(2023, 10, 22),
        imageUrl: "./img/pastGigPosters/LuciaPoster.webp",
        videoPosterUrl: null,
        // videoPosterUrl: "https://www.youtube.com/watch?v=Yoyqt21PMQQ",
        altTxt: "Lucia Hyde Park Poster",
        link: "",
        showInLiveSection: false,
        showInLinksPage: false,
        showInOneSheet: false,
        showInPastGigsSection: true,
        visibleDate: new Date(2024, 1, 14),
        expireDate: new Date(2124, 2, 14)
    },
    {
        id: 12,
        location: "Manchester",
        venue: "Deaf Institute",
        date: new Date(2024, 2, 14),
        imageUrl: "./img/pastGigPosters/bryde.jpg",
        videoPosterUrl: null,
        altTxt: "Deaf Institute Poster",
        link: "",
        showInLiveSection: false,
        showInLinksPage: false,
        showInOneSheet: false,
        showInPastGigsSection: true,
        visibleDate: new Date(2024, 1, 14),
        expireDate: new Date(2124, 2, 14)
    },
];