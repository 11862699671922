import { faFacebookF, faInstagram, faSpotify, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getSocialStats } from "../../services/socialStatsService";
import { SocialStats } from "../../services/interfaces";

function SocialStatsSection() {

    const [socialStats, setSocialStats] = useState<SocialStats | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const stats = await getSocialStats();
                setSocialStats(stats);
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch social stats");
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    return (

        <section className="live-alt" id="live-alt">

            <Row className="section-intro">
                <Col className="col-twelve">
                    <h5>SOCIALS</h5>
                </Col>
            </Row>
            <Row className="portfolio-content" style={{ textAlign: 'center' }}>
                <Col className="col-three onesheet-social">
                    <a href="https://www.instagram.com/avainthedark/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} size="xl" style={{ marginBottom: "20px" }} />
                        <h3>Instagram</h3>
                        <h3>Followers: {!error || 0 ? (loading ? "..." : socialStats?.InstagramFollowers) : 1716}</h3>
                    </a>
                </Col>
                <Col className="col-three onesheet-social">
                    <a href="https://open.spotify.com/artist/5UFq82PvmEauTLHRqGJIFv?si=AEvAfv0zQFGyIFu5T8aJUw" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faSpotify} size="xl" style={{ marginBottom: "20px" }} />
                        <h3>Spotify</h3>
                            <h3 style={{ marginBottom: "0.2px" }}> Followers: {!error ? (loading ? "..." : socialStats?.SpotifyFollowers) : 723}</h3>
                            <h3 >Monthly Listeners: {!error || 0 ? (loading ? "..." : socialStats?.SpotifyMonthlyListeners) : 1986}</h3>
                    </a>
                </Col>
                <Col className="col-three onesheet-social">
                    <a href="https://www.tiktok.com/@avainthedark" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faTiktok} size="xl" style={{ marginBottom: "20px" }} />
                        <h3>TikTok</h3>
                        <h3>Followers: {!error || 0 ? (loading ? "..." : socialStats?.TikTokFollowers) : 880}</h3>
                    </a>
                </Col>
                <Col className="col-three onesheet-social">

                    <a href="https://www.facebook.com/AvaInTheDark/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebookF} size="xl" style={{ marginBottom: "20px" }} />
                        <h3>Facebook</h3>
                        <h3>Followers: {!error || 0 ? (loading ? "..." : socialStats?.FacebookFollowers) : 869}</h3>
                    </a>
                </Col>
            </Row>
        </section>
    );
}

export default SocialStatsSection;