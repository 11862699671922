import React from "react";

import { Col, Row } from "react-bootstrap";
import FolioItem, { EditGigFolioItem, NewGigFolioItem } from "../live/folio-item";
import { useIsAuthenticated } from '@azure/msal-react';
import { IGig } from "../../services/interfaces";

function Live() {
    const isAuthenticated = useIsAuthenticated();

    return (

        <section className="live" id="live">
            {/* <video className='full-bk-video' playsInline autoPlay muted loop poster="../images/live-bg.JPG" src="./img/AmazonsClips.mp4" id="bgvid" typeof="video/mp4" /> */}


            <Row className="section-intro">
                <Col className="col-twelve">
                    <h5>UPCOMING SHOWS</h5>
                </Col>
            </Row>
            <Row className="portfolio-content">
                <Col className="col-twelve">
                    <div className="block-1-3 block-mob-full stack" id="folio-wrapper" style={{ position: 'relative', height: '340.094px' }}>
                        {isAuthenticated ? (
                            <>
                                {tempGigs.map((gig, index) => (
                                    
                                    <EditGigFolioItem key={index} gig={gig} />
                                ))}
                                <NewGigFolioItem />
                            </>
                        ) : (
                                tempGigs.map((gig, index) => (
                                    gig.showInLiveSection && new Date() > gig.visibleDate && new Date() < gig.expireDate &&
                                <FolioItem key={index} gig={gig} />
                            ))
                        )}
                    </div>
                </Col>
            </Row>
        </section>
    );
}

export default Live;

export const tempGigs: IGig[] = [
    {
        id: 1,
        location: "Leeds",
        venue: "Crash Records - Acoustic Set",
        //months are from 0!
        date: new Date(2025, 0, 18),
        imageUrl: "./img/gigPosters/CrashRecordsGigPoster.webp",
        videoPosterUrl: null,
        altTxt: "18thJan_CrashRecords_Poster",
        link: "https://www.crashrecords.co.uk/products/ava-in-the-dark-18-01-2025-crash-records",
        showInLiveSection: true,
        showInLinksPage: true,
        showInOneSheet: true,
        showInPastGigsSection: false,
        //months are from 0!
        visibleDate: new Date(2025, 0, 5),
        //months are from 0!
        expireDate: new Date(2025, 1, 1)
    },
    {
        id: 2,
        location: "Hull",
        venue: "Polar Bear Music Club - Headline",
        date: new Date(2025, 0, 21),
        imageUrl: "./img/gigPosters/avainthedark-sesh-jan2025.png",
        videoPosterUrl: null,
        altTxt: "TheSeshJanuaryPoster",
        link: "https://www.theseshhull.co.uk/sesh/21st-jan-25/",
        showInLiveSection: true,
        showInLinksPage: true,
        showInOneSheet: true,
        showInPastGigsSection: false,
        visibleDate: new Date(2025, 0, 5),
        expireDate: new Date(2025, 1, 1)
    },
    {
        id: 3,
        location: "Leeds",
        venue: "Oporto",
        date: new Date(2024, 2, 14),
        imageUrl: "./img/live/cielposter3.webp",
        videoPosterUrl: null,
        altTxt: "CielOportoPoster",
        link: "https://futuresound.seetickets.com/event/ciel/oporto/2798747?offercode=support&direct=true",
        showInLiveSection: false,
        showInLinksPage: false,
        showInOneSheet: false,
        showInPastGigsSection: false,
        visibleDate: new Date(2024, 1, 14),
        expireDate: new Date(2024, 2, 14)
    }];