import React from "react";
import { Row, Col } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faYoutube, faSpotify, faItunesNote, faBandcamp, faTiktok } from "@fortawesome/free-brands-svg-icons";


export default function LinksIntro() {
    return (
        <section id="intro" className="intro" style={{ maxHeight: "2px", minHeight: "1vh", backgroundImage: 'none' }}>
            <div className="intro-content" style={{ verticalAlign: "top", maxHeight: "1vh", paddingBottom: "0" }}>

                <Row>
                    <Col className="col-twelve">

                    </Col>
                </Row>
                <Row>
                    <Col className="col-twelve">

                        <h1 style={{
                            fontFamily: "Cormorant Garamond, serif",
                            fontSize: "38px", fontStyle: "normal",
                            fontWeight: "400",
                            letterSpacing: "0.1em",
                            marginTop: "4rem",
                            marginBottom: "4rem",
                            width: "100%"
                        }}>
                            AVA IN THE DARK
                        </h1>
                        <p className="intro-position"></p>
                    </Col>
                </Row>
                <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col className="col-twelve" style={{
                        width: "30vw",
                        maxHeight: "250px",
                        maxWidth: "250px",
                        borderRadius: "100%",
                        aspectRatio: "1 / 1",
                        overflow: "hidden",
                        justifyContent: "center",
                        alignItems: "center" 
                    }}>
                        <img
                            src="/img/bandshot-bluelight.JPG"
                            alt="spotify"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                objectPosition: "center",
                                transform: "scale(1.5)"
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-twelve">
                        <ul className="intro-social" style={{ marginTop: "4rem", marginBottom: "4rem" }}>
                            <li>
                                <a href="https://www.instagram.com/avainthedark/" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faInstagram} size="xl" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/channel/UCo-bPwCSh3jHZYl_W3i0t3A" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faYoutube} size="xl" />
                                </a>
                            </li>
                            <li>
                                <a href="https://open.spotify.com/artist/5UFq82PvmEauTLHRqGJIFv?si=AEvAfv0zQFGyIFu5T8aJUw" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faSpotify} size="xl" />
                                </a>
                            </li>
                            <li>
                                <a href="https://music.apple.com/gb/artist/ava-in-the-dark/1450407649" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faItunesNote} size="xl" />
                                </a>
                            </li>
                            <li>
                                <a href="https://avainthedark.bandcamp.com/?ltclid=e4ba4206-b697-42f2-8fb3-8b8733466afd" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faBandcamp} size="xl" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.tiktok.com/@avainthedark" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faTiktok} size="xl" />
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>


            </div>
        </section>
    );
}
