import { tempGigs } from "../components/home/live-section";
import LinksIntro from "../components/links/Links-intro-section";
import LinksMailingList from "../components/links/links-mailinglist-section";
import LinkList from "../components/links/linkList";
import { ILinks } from "../services/interfaces";

// Non-gig links
export const tempLinks: ILinks[] = [
    {
        id: 1,
        title: "Follow Ava in the Dark on Spotify",
        url: "https://open.spotify.com/artist/5UFq82PvmEauTLHRqGJIFv?si=V_Je1kKfTBesxaKlf7jpLQ",
        icon: "./img/SwimArtwork.jpg",
        visible: true,
        visibleDate: new Date(2024, 0, 1),
        expireDate: null,
    },
];

function Links() {
    // Transform gigs to links format
    const gigLinks: ILinks[] = tempGigs
        .filter(gig => gig.showInLinksPage && new Date() > gig.visibleDate && new Date() < gig.expireDate)
        .map(gig => ({
            id: gig.id +1000,
            title: `${gig.location} - ${gig.venue} - ${gig.date.toLocaleDateString()}`,
            url: gig.link,
            icon: gig.imageUrl,
            visible: true,
            visibleDate: gig.visibleDate,
            expireDate: gig.expireDate,
        }));

    const combinedLinks = [...tempLinks, ...gigLinks];

    return (
        <div
            className="links-page"
            style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}
        >
            <LinksIntro />
            <section id="live" className="live" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <div className="spotify-embed">
                    <iframe
                        title="spotify-swim"
                        style={{ borderRadius: "8px", maxWidth: "700px", width: "100%" }}
                        src="https://open.spotify.com/embed/track/2VJ3dOpeKUerHIn3Lyn2rw?utm_source=generator"
                        width="100%"
                        height="100"
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                        loading="lazy"
                    />
                </div>

                {combinedLinks.length > 0 && <LinkList links={combinedLinks} />}
            </section>
            <LinksMailingList />
        </div>
    );
}

export default Links;