import { Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faYoutube, faSpotify, faItunesNote, faBandcamp, faTiktok, faGoogleDrive } from "@fortawesome/free-brands-svg-icons";
import { useLocation } from "react-router-dom";
import { faTowerBroadcast } from "@fortawesome/free-solid-svg-icons";

export default function SwimPrIntro() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isFullVideo = queryParams.get("fullVid") === "true";


    //put shorter video in the deafult videoUrl
    const videoUrl = isFullVideo
        ? "https://youtu.be/H2CZFDXlZRw" // Full video URL (both)
        : "https://youtu.be/H2CZFDXlZRw";

    function YoutubeIcon() {
        return (
            <FontAwesomeIcon icon={faYoutube} size="6x" className="onesheet-intro-social" style={{ height: "50px" }} />
        );
    }

    return (
        <section id="swimpr-intro" className="swimpr-intro">
            {/* 
            <div className="intro-overlay">
            </div> */}
            <div className="intro-content">
                {/* <ReactPlayer
                url={"https://www.youtube.com/watch?v=Yoyqt21PMQQ"}
                playing={true}
                controls={false}
                loop={true}
                muted={true}
                width={"100%"}
                height={"102vh"}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: -1,
                    pointerEvents: 'none'
                }}
            /> */}
                <Row>
                    <Col className="col-twelve" style={{ alignContent: "center" }}>

                        <h1 style={{ fontFamily: "Cormorant Garamond, serif", fontSize: "84px", fontStyle: "normal", fontWeight: "400", letterSpacing: "0.1em", marginTop: "3rem" }}>
                            AVA IN THE DARK
                        </h1>
                        <h2 style={{ color: "white", fontSize: "3.2rem" }}>
                            RELEASES NEW TRACK 'SWIM' 29TH NOVEMBER 2024
                        </h2>
                        <div style={{ position: 'relative', paddingTop: '56.25%' }}>
                            <ReactPlayer
                                url={videoUrl}
                                playing={false}
                                controls={true}
                                loop={false}
                                // light={'./img/onesheet/swimvid-thumb.jpg'}
                                playIcon={<YoutubeIcon />}
                                muted={false}
                                width={"100%"}
                                height={"100%"}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    zIndex: 2
                                }}
                            />

                        </div>
                        <br />
                        <h3 style={{ color: "white", fontSize: "2.5rem" }}>
                            <a href="https://www.bbc.co.uk/programmes/p0k0y590" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTowerBroadcast} style={{marginRight:"1rem"}} />
                                PLAYED ON BBC INTRODUCING LEEDS & SHEFFIELD 28TH NOV
                            </a>
                        </h3>
                        <h3 style={{ color: "white", fontSize: "2.5rem" }}>
                            <a href="https://www.bbc.co.uk/programmes/m00258lb" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTowerBroadcast} style={{marginRight:"1rem"}}/>
                                PLAYED ON BBC RADIO 1 INTRODUCING ROCK TUES 3RD DEC
                            </a>
                        </h3>
                        <h3 style={{ color: "white", fontSize: "2.5rem" }}>
                            <a href="https://www.bbc.co.uk/programmes/m0025j8j" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTowerBroadcast} style={{marginRight:"1rem"}}/>
                                PLAYED ON BBC RADIO 1 INTRODUCING SUN 8TH DEC
                            </a>
                        </h3>
                        <h3 style={{ color: "white", fontSize: "2.5rem" }}>
                            <a href="https://www.bbc.co.uk/programmes/m0025zxx" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTowerBroadcast} style={{marginRight:"1rem"}}/>
                                PLAYED ON BBC RADIO WALES INTRODUCING SAT 14TH DEC
                            </a>
                        </h3>

                        <p className="intro-position"></p>
                        <ul className="onesheet-intro-social">
                            <li>
                                <a href="https://www.instagram.com/avainthedark/" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faInstagram} size="xl" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/channel/UCo-bPwCSh3jHZYl_W3i0t3A" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faYoutube} size="xl" />
                                </a>
                            </li>
                            <li>
                                <a href="https://open.spotify.com/artist/5UFq82PvmEauTLHRqGJIFv?si=AEvAfv0zQFGyIFu5T8aJUw" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faSpotify} size="xl" />
                                </a>
                            </li>
                            <li>
                                <a href="https://music.apple.com/gb/artist/ava-in-the-dark/1450407649" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faItunesNote} size="xl" />
                                </a>
                            </li>
                            <li>
                                <a href="https://avainthedark.bandcamp.com/?ltclid=e4ba4206-b697-42f2-8fb3-8b8733466afd" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faBandcamp} size="xl" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.tiktok.com/@avainthedark" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faTiktok} size="xl" />
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-twelve" style={{ alignContent: "center" }}>
                        <h2 style={{ textAlign: "center", fontSize: "3.6rem", color: "white" }}>
                            <a href="https://drive.google.com/drive/folders/15ioRJjDLRT5Yp4c6ozObSm2kZuF5pzYh?usp=sharing" target="_blank" rel="noopener noreferrer">
                                DOWNLOAD ASSETS
                                <FontAwesomeIcon icon={faGoogleDrive} style={{marginLeft:"1rem"}}/>
                            </a>
                        </h2>
                    </Col>
                </Row>
            </div>
        </section>
    );
}
