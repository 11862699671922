import axios from "axios";
import { SocialStats } from "./interfaces";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getSocialStats = async (): Promise<SocialStats> => {
  try {
    const response = await axios.get<SocialStats>(
      `${API_BASE_URL}/GetSocialStats`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching social stats:", error);
    throw error;
  }
};
